import { actionsWrapper as AppActions } from '@pixcap/ui-core/store/app';
import { DEFAULT_APP_LOCALE } from '@/constants/app.constants';

export default function (context) {
	AppActions.setLocale(context.store, context.route.params.locale);
	const { app } = context;
	app.head.htmlAttrs = {
		lang: context.route.params.locale || DEFAULT_APP_LOCALE,
	};
}
