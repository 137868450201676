import i18n from '@/config/i18n';
import { DEFAULT_APP_LOCALE } from '@/constants/app.constants';
import blogMessages from '@/lang/blog';
import { DEFAULT_BLOG_LOCALE } from '@/constants/blog.constants';

export default (context, inject) => {
	const { app, route } = context;
	app.i18n = context.i18n = i18n;
	context.i18n.locale = route.params.locale || DEFAULT_APP_LOCALE;

	const isBlogPage = route.meta.some((meta) => meta.isBlogRoute);
	if (isBlogPage) {
		let blogTranslation = blogMessages[DEFAULT_BLOG_LOCALE];
		if (route.params.locale in blogMessages) {
			blogTranslation = blogMessages[route.params.locale];
		}
		const existingMessages = context.i18n.getLocaleMessage(context.i18n.locale);
		const mergedMessages = { ...existingMessages, ...blogTranslation };
		context.i18n.setLocaleMessage(context.i18n.locale, mergedMessages);
	}
};
