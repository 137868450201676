// used plugin for auth instead of middleware because middlware it can be controlled to run in client or server
import { getCookie } from '@pixcap/ui-core/utils/WindowUtils';
import { C_SESSION_COOKIE_KEY, JWT_TOKEN_COOKIE_KEY, SEGMENT_COOKIE_KEY } from '@pixcap/ui-core/constants/app.constants';
import { mutationsWrapper as AuthMutations, actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
import { actionsWrapper as UserActions } from '@pixcap/ui-core/store/user';
import { mutationsWrapper as AppMutation } from '@pixcap/ui-core/store/app/wrapper';
export default (context, inject) => {
	window.onNuxtReady(async () => {
		try {
			const cookie = document.cookie;
			const cSession = getCookie(cookie, C_SESSION_COOKIE_KEY);
			const jwtToken = getCookie(cookie, JWT_TOKEN_COOKIE_KEY);
			const segmentIndex = getCookie(cookie, SEGMENT_COOKIE_KEY);
			let fetchPersonalData = false;
			if (jwtToken) {
				fetchPersonalData = true;
				AuthMutations.store(context.store, { idToken: jwtToken } as any);
			} else if (cSession) {
				fetchPersonalData = await AuthActions.refresh(context.store);
			}
			if (segmentIndex) {
				AppMutation.setAbTesting(context.store, segmentIndex);
			}
			if (fetchPersonalData) {
				await UserActions.fetchPersonalData(context.store);
			}
		} catch (err) {}
	});
};
