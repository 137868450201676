import { httpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';
import logger from '@pixcap/ui-core/helpers/logger';
import { Store } from 'vuex/types/index';
import {
	ActionTypes,
	StrapiArticle,
	PixcapArticle,
	NAMESPACE,
	IBlog,
	StrapiCategory,
	PixcapBlogCategory,
	StrapiPaginationMeta,
} from '@/models/store/blog.interface';
import { AVAILABLE_BLOG_LOCALES, DEFAULT_BLOG_LOCALE } from '@/constants/blog.constants';

const BLOG_PATH = API_PATHS.BLOG_PATH;

const COMMON_FIELDS = [
	'title',
	'slug',
	'publishedAt',
	'thumbnail_alt_text',
	// 'description'
];

const blogLocaleToStrapiLocale = (blogLocale: string) => {
	return AVAILABLE_BLOG_LOCALES.find((locale) => locale.route_params == blogLocale)?.strapi_code;
};

const mapStrapiCategory = (strapiCategory: StrapiCategory) => {
	const { attributes: rawAttributeData } = strapiCategory;
	const category: PixcapBlogCategory = {
		name: rawAttributeData.name,
		slug: rawAttributeData.slug,
		seo: rawAttributeData.seo || null,
		otherAvailableLocales: rawAttributeData.localizations?.data.map((otherAvailableLocale) => {
			return {
				locale: otherAvailableLocale.attributes.locale,
				slug: otherAvailableLocale.attributes.slug,
				name: otherAvailableLocale.attributes.name,
			};
		}),
	};
	return category;
};

const mapStrapiArticle = (strapiArticle: StrapiArticle) => {
	const { attributes: rawAttributeData } = strapiArticle;
	const article: PixcapArticle = {
		title: rawAttributeData.title,
		description: rawAttributeData.description,
		largeThumbnailUrl: rawAttributeData.thumbnail?.data
			? rawAttributeData.thumbnail.data.attributes.formats?.large?.url || rawAttributeData.thumbnail.data.attributes.url
			: null,
		thumbnailUrl: rawAttributeData.thumbnail?.data
			? rawAttributeData.thumbnail.data.attributes.formats?.medium?.url || rawAttributeData.thumbnail.data.attributes.url
			: null,
		thumbnailAltText: rawAttributeData.thumbnail_alt_text ?? rawAttributeData.title,
		content: rawAttributeData.content,
		category: rawAttributeData.category?.data ? mapStrapiCategory(rawAttributeData.category.data) : null,
		slug: rawAttributeData.slug,
		publishedAt: new Date(rawAttributeData.publishedAt),
		seo: rawAttributeData.seo || null,
		otherAvailableLocales: rawAttributeData.localizations?.data.map((otherAvailableLocale) => {
			return {
				locale: otherAvailableLocale.attributes.locale,
				slug: otherAvailableLocale.attributes.slug,
			};
		}),
	};
	return article;
};

export default {
	async [ActionTypes.GET_ARTICLE_BY_SLUG](
		{ state }: Store<IBlog>,
		payload: {
			slug?: string;
		}
	) {
		try {
			const { slug } = payload;
			const params = {
				'pagination[limit]': 1,
				'populate[thumbnail][fields]': '*',
				'populate[category][fields]': '*',
				'populate[seo][fields]': '*',
				'populate[localizations][fields][0]': 'locale', // Get other available locales of this article
				'populate[localizations][fields][1]': 'slug', // Get other available locales of this article
				'filters[slug][$eq]': slug,
				locale: blogLocaleToStrapiLocale(state.locale ?? DEFAULT_BLOG_LOCALE),
			};
			const response = await httpClient.get(`${BLOG_PATH}/articles`, { params });
			if (response.data?.data?.length) {
				const strapiArticle = response.data.data[0];
				return mapStrapiArticle(strapiArticle);
			}
			return null;
		} catch (e) {
			logger.log('error fetching blog data', e);
			return null;
		}
	},

	async [ActionTypes.GET_ARTICLES](
		{ state }: Store<IBlog>,
		payload: { page?: number; pageSize?: number }
	): Promise<[articles: PixcapArticle[], pagination: StrapiPaginationMeta]> {
		try {
			const { page = 1, pageSize = 4 } = payload;
			const params = {
				'pagination[page]': page,
				'pagination[pageSize]': pageSize,
				populate: ['thumbnail', 'category'],
				locale: blogLocaleToStrapiLocale(state.locale ?? DEFAULT_BLOG_LOCALE),
				fields: COMMON_FIELDS,
				sort: 'publishedAt:desc',
			};
			const response = await httpClient.get(`${BLOG_PATH}/articles`, { params });
			if (response.data?.data?.length) {
				const responseArticles: StrapiArticle[] = response.data.data;
				const responsePagination: StrapiPaginationMeta = response.data.meta.pagination;

				const articles = [];
				responseArticles.forEach((strapiArticle) => {
					articles.push(mapStrapiArticle(strapiArticle));
				});
				return [articles, responsePagination];
			}
			return [[], null];
		} catch (e) {
			logger.log('error fetching blog data', e);
			return [[], null];
		}
	},

	async [ActionTypes.GET_RELATED_ARTICLES](
		{ state }: Store<IBlog>,
		payload: { page?: number; pageSize?: number; articleSlug?: string; categorySlug?: string }
	): Promise<[articles: PixcapArticle[], pagination: StrapiPaginationMeta]> {
		try {
			const { articleSlug, categorySlug, page = 1, pageSize = 4 } = payload;
			const params = {
				'filters[slug][$ne]': articleSlug,
				'filters[category][slug][$eq]': categorySlug,
				'pagination[page]': page,
				'pagination[pageSize]': pageSize,
				populate: ['thumbnail', 'category'],
				locale: blogLocaleToStrapiLocale(state.locale ?? DEFAULT_BLOG_LOCALE),
				fields: COMMON_FIELDS,
				sort: 'publishedAt:desc',
			};
			const response = await httpClient.get(`${BLOG_PATH}/articles`, { params });
			if (response.data?.data?.length) {
				const responseArticles: StrapiArticle[] = response.data.data;
				const responsePagination: StrapiPaginationMeta = response.data.meta.pagination;

				const articles = [];
				responseArticles.forEach((strapiArticle) => {
					articles.push(mapStrapiArticle(strapiArticle));
				});
				return [articles, responsePagination];
			}
			return [[], null];
		} catch (e) {
			logger.log('error fetching blog data', e);
			return [[], null];
		}
	},

	async [ActionTypes.GET_ARTICLES_BY_CATEGORY](
		{ state }: Store<IBlog>,
		payload: { categorySlug: string; page?: number; pageSize?: number }
	): Promise<[articles: PixcapArticle[], pagination: StrapiPaginationMeta]> {
		try {
			const { categorySlug, page = 1, pageSize = 3 } = payload;
			const params = {
				'pagination[page]': page,
				'pagination[pageSize]': pageSize,
				populate: ['thumbnail', 'category'],
				'filters[category][slug][$eq]': categorySlug,
				locale: blogLocaleToStrapiLocale(state.locale ?? DEFAULT_BLOG_LOCALE),
				fields: COMMON_FIELDS,
				sort: 'publishedAt:desc',
			};
			const response = await httpClient.get(`${BLOG_PATH}/articles`, { params });
			if (response.data?.data?.length) {
				const responseArticles: StrapiArticle[] = response.data.data;
				const responsePagination: StrapiPaginationMeta = response.data.meta.pagination;

				const articles = [];
				responseArticles.forEach((strapiArticle) => {
					articles.push(mapStrapiArticle(strapiArticle));
				});
				return [articles, responsePagination];
			}
			return [[], null];
		} catch (e) {
			logger.log('error fetching blog data', e);
			return [[], null];
		}
	},

	async [ActionTypes.GET_CATEGORY_BY_SLUG]({ state }: Store<IBlog>, payload: { categorySlug: string }): Promise<PixcapBlogCategory | null> {
		try {
			const { categorySlug } = payload;
			const params = {
				'pagination[limit]': 1,
				'filters[slug][$eq]': categorySlug,
				'populate[seo][fields]': '*',
				'populate[localizations][fields][0]': 'locale', // Get other available locales of this article
				'populate[localizations][fields][1]': 'slug', // Get other available locales of this article
				locale: blogLocaleToStrapiLocale(state.locale ?? DEFAULT_BLOG_LOCALE),
			};
			const response = await httpClient.get(`${BLOG_PATH}/categories`, { params });
			if (response.data?.data?.length) {
				const strapiCategory = response.data.data[0];
				return mapStrapiCategory(strapiCategory);
			}
			return null;
		} catch (e) {
			logger.log('error fetching category data', e);
			return null;
		}
	},

	async [ActionTypes.GET_CATEGORIES]({ state }: Store<IBlog>): Promise<PixcapBlogCategory[] | []> {
		try {
			const params = {
				'populate[localizations][fields][0]': 'locale', // Get other available locales of this article
				'populate[localizations][fields][1]': 'slug', // Get other available locales of this article
				'populate[localizations][fields][2]': 'name', // Get other available locales of this article
				locale: blogLocaleToStrapiLocale(state.locale ?? DEFAULT_BLOG_LOCALE),
			};
			const response = await httpClient.get(`${BLOG_PATH}/categories`, { params });
			if (response.data?.data?.length) {
				const strapiCategories = response.data.data;

				const categories = [];
				strapiCategories.forEach((strapiCategory) => {
					categories.push(mapStrapiCategory(strapiCategory));
				});
				return categories;
			}
			return [];
		} catch (e) {
			logger.log('error fetching category data', e);
			return [];
		}
	},
};
