import { MutationTypes, IOrganismState } from '@/models/store/organism.interface';

export default {
	[MutationTypes.SET_SHOULD_CUSTOMIZE_HEADER](state: IOrganismState, shouldCustomize: boolean) {
		state.shouldCustomizeHeader = shouldCustomize;
	},
	[MutationTypes.SET_SHOULD_SHOW_SEARCH_INPUT](state: IOrganismState, isShow: boolean) {
		state.shouldShowSearchInput = isShow;
	},
	[MutationTypes.SET_SHOW_TUTORIAL_MODAL](state: IOrganismState, embedLink: string) {
		state.showTutorialModal = embedLink;
	},
	[MutationTypes.SET_SHOW_PROMOTION_HEADER](state: IOrganismState, isShow: boolean) {
		state.showPromotionHeader = isShow;
	},
	[MutationTypes.SET_CATEGORY_COUNT](state: IOrganismState, payload) {
		state.categoryCount = payload;
	},
	[MutationTypes.SET_SHOULD_HIGHLIGHT_HEADER](state: IOrganismState, isHighlight: boolean) {
		state.shouldHighlightHeader = isHighlight;
	},
};
