
	import { Component, Prop, ProvideReactive, Vue } from 'vue-property-decorator';
	import ErrorPage from '@pixcap/ui-library/components/ErrorPage.vue';
	import { CONTEXT_PROVIDER_TYPES } from '@pixcap/ui-core/models/widgets/contextProvider.interface';
	import { mutationsWrapper as OrganismMutations } from '@/store/organism/wrapper';
	import ExploreHeader from '@/components/layouts/default/ExploreHeader.vue';

	@Component({
		name: 'Error',
		layout: 'empty',
		components: {
			ExploreHeader,
			ErrorPage,
		},
	})
	export default class Error extends Vue {
		@Prop() error: any;

		_handleScreenSize;

		@ProvideReactive() isMobileScreen: boolean = false;
		@ProvideReactive() isTabletScreen: boolean = false;
		@ProvideReactive() isDesktopScreen: boolean = true;

		handleScreenSize() {
			if (window.innerWidth <= 743) {
				this.isMobileScreen = true;
				this.isTabletScreen = false;
				this.isDesktopScreen = false;
			} else if (window.innerWidth <= 1024) {
				this.isMobileScreen = false;
				this.isTabletScreen = true;
				this.isDesktopScreen = false;
			} else {
				this.isMobileScreen = false;
				this.isTabletScreen = false;
				this.isDesktopScreen = true;
			}
		}

		mounted() {
			OrganismMutations.setShowPromotionHeader(this.$store, null);
			this._handleScreenSize = this.handleScreenSize.bind(this);
			window.addEventListener('resize', this._handleScreenSize);
			this.handleScreenSize();
		}

		beforeDestroy() {
			const { contextProvider } = this.$pixcap.$widgets;
			contextProvider.resetProviderInjections(CONTEXT_PROVIDER_TYPES.DASHBOARD_LAYOUT);
			window.removeEventListener('resize', this._handleScreenSize);
		}
	}
