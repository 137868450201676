import { NAMESPACE as APP_NAMESPACE } from '@pixcap/ui-core/models/store/app.interface';
import { actions as AppActions, mutations as AppMutations, state as AppState, getters as AppGetters } from '@pixcap/ui-core/store/app';
import { NAMESPACE as AUTH_NAMESPACE } from '@pixcap/ui-core/models/store/auth.interface';
import { actions as AuthActions, mutations as AuthMutations, state as AuthState, getters as AuthGetters } from '@pixcap/ui-core/store/auth';
import { NAMESPACE as USER_NAMESPACE } from '@pixcap/ui-core/models/store/user.interface';
import { actions as UserActions, mutations as UserMutations, state as UserState, getters as UserGetters } from '@pixcap/ui-core/store/user';
import { NAMESPACE as PROJECTEXPORTS_NAMESPACE } from '@pixcap/ui-core/models/store/projectexports.interface';
import {
	actions as ExportActions,
	mutations as ExportMutations,
	state as ExportState,
	getters as ExportGetters,
} from '@pixcap/ui-core/store/projectexports';
import { NAMESPACE as SURVEY_NAMESPACE } from '@pixcap/ui-core/models/store/survey.interface';
import { actions as SurveyActions, mutations as SurveyMutations, state as SurveyState } from '@pixcap/ui-core/store/survey';

import ReadyPlayerMeModule, { NAMESPACE as READY_PLAYER_ME_NAMESPACE } from '@pixcap/ui-core/store/readyplayerme';

import { NAMESPACE as PROJECT_NAMESPACE } from '@/models/store/project.interface';
import ProjectState from '@/store/project/state';
import ProjectMutations from '@/store/project/mutations';
import ProjectActions from '@/store/project/actions';

import { NAMESPACE as ORGANISM_NAMESPACE } from '@/models/store/organism.interface';
import OrganismState from '@/store/organism/state';
import OrganismMutations from '@/store/organism/mutations';
import OrganismActions from '@/store/organism/actions';

import { NAMESPACE as PREVIEW_NAMESPACE } from '@/models/store/preview.interface';
import PreviewState from '@/store/preview/state';
import PreviewMutations from '@/store/preview/mutations';

import { NAMESPACE as COMMUNITY_NAMESPACE } from '@/models/store/community.interface';
import CommunityState from '@/store/community/state';
import CommunityMutations from '@/store/community/mutations';
import CommunityActions from '@/store/community/actions';

import { NAMESPACE as LIBRARY_NAMESPACE } from '@/models/store/pixcapassets.interface';
import LibraryState from '@/store/pixcapassets/state';
import LibraryMutations from '@/store/pixcapassets/mutations';
import LibraryActions from '@/store/pixcapassets/actions';
import LibraryGetters from '@/store/pixcapassets/getters';

import { NAMESPACE as BLOG_NAMESPACE } from '@/models/store/blog.interface';
import BlogState from '@/store/blog/state';
import BlogMutations from '@/store/blog/mutations';
import BlogActions from '@/store/blog/actions';

const modules = {};
if (process.env.APP_MODE !== 'jest') {
	modules[APP_NAMESPACE] = {
		namespaced: true,
		state: AppState,
		mutations: AppMutations,
		actions: AppActions,
		getters: AppGetters,
	};
	modules[AUTH_NAMESPACE] = {
		namespaced: true,
		state: AuthState,
		mutations: AuthMutations,
		actions: AuthActions,
		getters: AuthGetters,
	};
	modules[USER_NAMESPACE] = {
		namespaced: true,
		state: UserState,
		mutations: UserMutations,
		actions: UserActions,
		getters: UserGetters,
	};
	modules[PROJECTEXPORTS_NAMESPACE] = {
		namespaced: true,
		state: ExportState,
		mutations: ExportMutations,
		actions: ExportActions,
		getters: ExportGetters,
	};
	modules[SURVEY_NAMESPACE] = {
		namespaced: true,
		state: SurveyState,
		mutations: SurveyMutations,
		actions: SurveyActions,
	};
	modules[READY_PLAYER_ME_NAMESPACE] = ReadyPlayerMeModule;
	modules[PROJECT_NAMESPACE] = {
		namespaced: true,
		state: ProjectState,
		mutations: ProjectMutations,
		actions: ProjectActions,
	};
	modules[COMMUNITY_NAMESPACE] = {
		namespaced: true,
		state: CommunityState,
		mutations: CommunityMutations,
		actions: CommunityActions,
	};

	modules[ORGANISM_NAMESPACE] = {
		namespaced: true,
		state: OrganismState,
		mutations: OrganismMutations,
		actions: OrganismActions,
	};

	modules[PREVIEW_NAMESPACE] = {
		namespaced: true,
		state: PreviewState,
		mutations: PreviewMutations,
	};

	modules[LIBRARY_NAMESPACE] = {
		namespaced: true,
		state: LibraryState,
		mutations: LibraryMutations,
		actions: LibraryActions,
		getters: LibraryGetters,
	};

	modules[BLOG_NAMESPACE] = {
		namespaced: true,
		state: BlogState,
		mutations: BlogMutations,
		actions: BlogActions,
	};
}

export default modules;
