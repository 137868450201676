export enum HomepageSection {
	ESSENTIAL_ICON_PACKS = 'essential-icon-packs',
	ESSENTIAL_SCENE_PACKS = 'essential-scene-packs',
	ESSENTIAL_MOCKUP_PACKS = 'mockup-packs',

	ICON = 'icon',
	CHARACTER = 'character',
	DEVICE_MOCKUP = 'device-mockup',
	BRANDING_MOCKUP = 'branding-mockup',
}

export enum SearchPageSection {
	ICON = 'icon',
	CHARACTER = 'character',
	MOCKUP = 'mockup',
}

export interface IOrganismState {
	shouldShowSearchInput: boolean;
	shouldCustomizeHeader: boolean;
	showTutorialModal: string;
	showPromotionHeader: boolean;

	shouldHighlightHeader: boolean;

	categoryCount: any;
}

export const NAMESPACE = 'organism';

export enum MutationTypes {
	SET_SHOULD_SHOW_SEARCH_INPUT = 'SET_SHOULD_SHOW_SEARCH_INPUT',
	SET_SHOULD_CUSTOMIZE_HEADER = 'SET_SHOULD_CUSTOMIZE_HEADER',
	SET_SHOW_TUTORIAL_MODAL = 'SET_SHOW_TUTORIAL_MODAL',
	SET_SHOW_PROMOTION_HEADER = 'SET_SHOW_PROMOTION_HEADER',

	SET_CATEGORY_COUNT = 'SET_CATEGORY_COUNT',

	SET_SHOULD_HIGHLIGHT_HEADER = 'SET_SHOULD_HIGHLIGHT_HEADER',
}

export enum ActionTypes {
	GET_FOOTER_SEO = 'GET_FOOTER_SEO',
	GET_CATEGORY_FOOTER_COUNT = 'GET_CATEGORY_FOOTER_COUNT',
}
